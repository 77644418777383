import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Table, Button, ButtonGroup, Modal, ModalBody, ModalHeader, Input, Label, Row, Col, Spinner, Container } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { API } from "../../../api";
import moment from "moment";
import { useInfiniteQuery } from "react-query";
import Loader from "../../../Components/Common/Loader";
import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";
import AmazingPassInfoModal from "../../../Components/modal/AmazingPassInfoModal";

const Item = styled.div`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  padding: 10px 20px;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const MoreButton = styled.button`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  padding: 7px 12px;
  background-color: white;
  font-family: "Gmarket-M";
  box-shadow: none;
  border: 1px solid #ff5a5f;
  color: #ff5a5f;
`;

function AmazingPassPurchaseList(props) {
  const navigate = useNavigate();

  const [modalBackdrop, setModalBackdrop] = React.useState(false);
  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const [couponModal, setCouponModal] = React.useState(false);
  const [selectCoupon, setSelectCoupon] = React.useState({});

  const accessToken = getAccessToken();

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await API.get(`/precoupon/user/?page=${pageParam}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;

      return { ...data, results: data.results };
    },
    [accessToken]
  );

  const { status, data, error, isSuccess, isFetching, isLoading, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } =
    useInfiniteQuery(
      ["AmazingPassList"],
      async ({ pageParam = 1 }) => {
        // console.log("pageParam", pageParam);
        const data = await fetchData({ pageParam });
        // console.log("orderdata", data);
        return data;
      },
      {
        // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
        getNextPageParam: (lastPage) => {
          if (lastPage && lastPage.next) {
            const match = lastPage.next.match(/page=(\d+)/);
            if (match) {
              return match[1];
            }
          }
          return undefined;
        },
        // getPreviousPageParam: (firstPage) => undefined,
      }
    );

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
            <Row>

              <div className="px-4 py-2 mt-4" style={{
                width: "100%",
              }}>
                <Button color="success" style={{
                  fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff",
                  fontSize: "14px",
                  width: "100%",
                 }} onClick={() => navigate("/amazingpass")}>
                  어메이징패스로 구매하기
                </Button>
              </div>

              <Col className="col-12 d-flex flex-wrap justify-content-center gap-4 mt-4">


                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : null}

                {data && isSuccess ? (
                  <>
                    {data?.pages.map((page, index) => (
                      <React.Fragment key={index}>
                        {page.results.map((item, index) => (
                          <React.Fragment key={"item" + index}>
                            <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={item.id} className="">
                              <Item
                                // visible={!item.gifted}
                                visible={true}
                                className="bg-white"
                                style={{
                                  opacity: item.state !== "사용안함" ? "0.5" : "1",
                                }}
                              >
                                <div style={{ float: "right" }}>
                                  <Button
                                    style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", fontSize: "12px" }}
                                    color="success"
                                    onClick={() => {
                                      setSelectCoupon(item);
                                      setCouponModal(true);
                                    }}
                                  >
                                    상세보기
                                  </Button>
                                </div>
                                <div className="d-flex py-3 align-items-flex-start justify-content-start mt-2" key={"product" + index} style={{ borderBottom: "1px solid #ededed" }}>
                                  <div className="avatar-md mx-2">
                                    <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                      <img src={`${item.image}`} alt="" className="img-fluid" style={{ borderRadius: "5px" }} />
                                    </div>
                                  </div>
                                  <div className="mt-0">
                                    <div className="card-title fw-bold" style={{ marginLeft: "10px" }}>
                                      {item.precoupon_name}
                                    </div>

                                    <div style={{ marginLeft: "10px" }}>만료기한 : {moment(item.expire_date).format("YYYY-MM-DD")}</div>
                                    <div style={{ marginLeft: "10px" }}>상태 : {item.state}</div>

                                  </div>
                                </div>
                              </Item>
                            </Col>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                ) : null}

                {hasNextPage && (
                  <MoreButton disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
                    더보기
                  </MoreButton>
                )}

                {data && data?.pages[0].count === 0 ? (
                  <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 250 }}>
                    <Loader loading={isLoading} />
                  </div>
                ) : null}
              </Col>

              <AmazingPassInfoModal setVisible={setCouponModal} visible={couponModal} info={selectCoupon} setInfo={setSelectCoupon} refetch={refetch} />
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AmazingPassPurchaseList;
