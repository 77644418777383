import React from 'react'
import Navbar from '../../../Components/Common/navbar'
import { useLocation, useNavigate } from 'react-router-dom';
import AmazingpassUseModal from './amazingpassUseModal';
import { getAccessToken } from '../../../api/auth';
import { API } from '../../../api';

export default function AmazingpassOrderScreen() {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        product,
        selectDate,
        spotId
    } = location.state;

    console.log("AmazingpassOrderScreen", product, selectDate, spotId);

    const accessToken = getAccessToken();

    const [couponModal, setCouponModal] = React.useState(false);

    const togModal = () => {
        setCouponModal(!couponModal);
        document.body.style.overflow = "scroll";
    }

    const [selectedSeat, setSelectedSeat] = React.useState(null);
    const [selectedPrecoupons, setSelectedPrecoupons] = React.useState([]);

    const onSelectCoupon = (data) => {
        console.log("onSelectCoupon", data);
        // data.seat
        // data.precoupon
        setSelectedPrecoupons([...selectedPrecoupons, data]);
    }

    const processAmazingPass = async () => {
        if (!selectedPrecoupons.length) {
            alert("예약권을 선택해주세요.");
            return;
        }
        if (selectedPrecoupons.length !== spotId.length) {
            if (!window.confirm("예약권이 사용되지 않은 상품이 있습니다! \n예약권이 선택된 상품만 결제됩니다. \n계속하시겠습니까?")) {
                return;
            }
        }

        const precoupon_results = [];
        for (let j = 0; j < selectedPrecoupons.length; j++) {
            const item = selectedPrecoupons[j];
            const precoupon_result = await API.put(
                `/precoupon/use-coupon/${item.precoupon.id}/`,
                {
                    user_coupon_id: item.precoupon.id,
                    spot_id: item.seat.id,
                    start_date: selectDate,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            precoupon_results.push(precoupon_result.data);
        }
        console.log("precoupon results:", precoupon_results);

        if (precoupon_results.length === selectedPrecoupons.length) {
            alert("예약권 사용이 완료되었습니다.");
        }
        navigate('/my-page/reservation', { replace: true });
    }

    return (
        <React.Fragment>
            <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
                <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <Navbar />

                    <div className='py-2' style={{ minHeight: "90vh" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                            <div style={{ fontSize: 19, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>어메이징패스로 구매하기</div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                            <div style={{ fontSize: 15, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>{product.name}</div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                            <div style={{ fontSize: 15, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>{selectDate}</div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        </div>

                        <div>
                            {spotId.map((spot, idx, obj) => {
                                const selectedPrecoupon = selectedPrecoupons.find((item) => item.seat.id === spot.id);
                                return (
                                    <div key={idx} className="fw-normal text-wrap mb-1 mx-1 px-4" style={{ borderBottom: "1px solid #ededed", padding: "10px 0" }}>
                                        <div>
                                            <span>
                                                <span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{spot.name}</span>번 {product.price.toLocaleString()}원
                                            </span>
                                        </div>
                                        {selectedPrecoupon ? (
                                            <div className="d-block" style={{ textAlign: "right" }}>
                                                <div><span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{selectedPrecoupon.precoupon.precoupon_name}</span> 사용</div>
                                                <p
                                                    className="fw-bold mt-2 btn btn-dark"
                                                    onClick={() => {
                                                        setSelectedSeat(null);
                                                        setSelectedPrecoupons(selectedPrecoupons.filter((item) => item.seat.id !== spot.id));
                                                    }}
                                                >
                                                    사용취소
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="d-block" style={{ textAlign: "right" }}>
                                                <p
                                                    className="fw-bold mt-2 btn btn-primary"
                                                    onClick={() => {
                                                        setSelectedSeat(spot);
                                                        setCouponModal(true);
                                                    }}
                                                >
                                                    예약권 선택하기
                                                </p>
                                            </div>
                                        )}
                                        {/* {idx !== obj.length - 1 ? ", " : ""} */}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="d-flex justify-content-center align-items-center gap-4 mt-4">
                            <button
                                className="btn btn-dark"
                                onClick={() => {
                                    navigate('/my-page', { replace: true });
                                }}
                            >
                                뒤로가기
                            </button>

                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    processAmazingPass()
                                }}
                            >
                                결제하기
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <AmazingpassUseModal visible={couponModal} togModal={togModal} onSelectCoupon={onSelectCoupon}
                selectedSeat={selectedSeat} selectedPrecoupons={selectedPrecoupons}
                product={product} selectDate={selectDate}
            />
        </React.Fragment>
    )
}
