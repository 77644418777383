import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Table, Button, ButtonGroup, Modal, ModalBody, ModalHeader, Input, Label, Row, Col, Spinner, Container } from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { API } from "../../../api";
import moment from "moment";
import { useInfiniteQuery } from "react-query";
import Loader from "../../../Components/Common/Loader";
import { getAccessToken } from "../../../api/auth";
import AmazingPassInfoModal from "../../../Components/modal/AmazingPassInfoModal";

const Item = styled.div`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  padding: 10px 20px;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const MoreButton = styled.button`
  border-radius: 0.5vh;
  /* max-width: 480px; */
  padding: 7px 12px;
  background-color: white;
  font-family: "Gmarket-M";
  box-shadow: none;
  border: 1px solid #ff5a5f;
  color: #ff5a5f;
`;

function AmazingpassUseModal({
    visible, togModal,
    selectedSeat,
    onSelectCoupon,
    selectedPrecoupons,
    product, selectDate,
}) {

    const accessToken = getAccessToken();

    const today = moment().format("YYYY-MM-DD");

    const fetchData = useCallback(
        async ({ pageParam = 1 }) => {
            const response = await API.get(`/precoupon/user/?page=${pageParam}&state=사용안함`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });
            const data = response.data;

            return { ...data, results: data.results };
        },
        [accessToken]
    );

    const { status, data, error, isSuccess, isFetching, isLoading, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } =
        useInfiniteQuery(
            ["AmazingPassList", "사용안함"],
            async ({ pageParam = 1 }) => {
                // console.log("pageParam", pageParam);
                const data = await fetchData({ pageParam });
                // console.log("orderdata", data);
                return data;
            },
            {
                // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
                getNextPageParam: (lastPage) => {
                    if (lastPage && lastPage.next) {
                        const match = lastPage.next.match(/page=(\d+)/);
                        if (match) {
                            return match[1];
                        }
                    }
                    return undefined;
                },
                // getPreviousPageParam: (firstPage) => undefined,
            }
        );
    
    if (!selectedSeat) {
        return null;
    }

    return (
        <React.Fragment>
            <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>

                <div className="mt-2 px-2 py-3">

                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <div style={{ fontSize: 19, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>어메이징패스 선택</div>
                        <div style={{ fontSize: 15, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>{selectedSeat?.name}번</div>
                    </div>

                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "50vh" }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : null}

                    <div style={{
                        height: "70vh",
                        overflowY: "auto",
                        overflowX: "hidden",

                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px",
                    }}>
                        {data && isSuccess ? (
                            <>
                                {data?.pages.map((page, index) => (
                                    <React.Fragment key={index}>
                                        {page.results.map((item, index) => {
                                            const isSelected = selectedPrecoupons.find((coupon) => coupon.precoupon.id === item.id && coupon.seat.id === selectedSeat.id);
                                            const isBlocked = !!selectedPrecoupons.find((coupon) => coupon.precoupon.id === item.id && coupon.seat.id !== selectedSeat.id);
                                            const isExpired = today > moment(moment(item.expire_date.slice(0, 10), "YYYY-MM-DD"));
                                            const isDateValid = moment(item.coupon_start_date) <= moment(selectDate) && moment(selectDate) <= moment(item.coupon_end_date);
                                            const isUsed = item.state !== "사용안함";
                                            const targetPackageMatched = item.available_packages?.includes(product.id);
                                            const isPriceValid = Number(item.discount) >= Number(product.price);
                                            const disable = isBlocked || isExpired || isUsed || !isDateValid;

                                            return (
                                                <React.Fragment key={"item" + index}>
                                                    <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={item.id} className="">
                                                        <Item
                                                            visible={!item.gifted}
                                                            // visible={true}
                                                            className="bg-white"
                                                        >
                                                            <div style={{ float: "right" }}>
                                                                <Button
                                                                    style={{
                                                                        fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", fontSize: "12px",
                                                                        opacity: disable || isSelected || !targetPackageMatched ? "0.5" : "1",
                                                                     }}
                                                                    color="success"
                                                                    onClick={() => {
                                                                        if (disable || isSelected) return;

                                                                        if (!targetPackageMatched) {
                                                                            alert("해당 패키지 상품에는 사용할 수 없는 쿠폰입니다.");
                                                                            return;
                                                                        }

                                                                        if (!isPriceValid) {
                                                                            alert("쿠폰 할인금액이 자리 가격보다 작습니다.");
                                                                            return;
                                                                        }

                                                                        onSelectCoupon({
                                                                            seat: selectedSeat,
                                                                            precoupon: item,
                                                                        });
                                                                        togModal();
                                                                    }}
                                                                >
                                                                    사용하기
                                                                </Button>
                                                            </div>
                                                            <div className="d-flex py-3 align-items-flex-start justify-content-start mt-2" key={"product" + index}>
                                                                <div className="avatar-md mx-2">
                                                                    <div className="avatar-title bg-soft-light text-success fs-2 rounded">
                                                                        <img src={`${item.image}`} alt="" className="img-fluid" style={{ borderRadius: "5px" }} />
                                                                    </div>
                                                                </div>
                                                                <div className="mt-0">
                                                                    <div className="card-title fw-bold" style={{ marginLeft: "10px" }}>
                                                                        {item.precoupon_name}
                                                                    </div>
                                                                    <div style={{ marginLeft: "10px" }}>만료기한 : {moment(item.expire_date).format("YYYY-MM-DD")}</div>
                                                                </div>
                                                            </div>
                                                        </Item>
                                                    </Col>
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}
                            </>
                        ) : null}

                        {hasNextPage && (
                            <MoreButton disabled={isFetchingNextPage} onClick={() => fetchNextPage()}>
                                더보기
                            </MoreButton>
                        )}

                        {data && data?.pages[0].count === 0 ? (
                            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 250 }}>
                                <Loader loading={isLoading} />
                            </div>
                        ) : null}

                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        <Button
                            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", fontSize: "12px" }}
                            color="success"
                            onClick={togModal}
                        >
                            닫기
                        </Button>
                    </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default AmazingpassUseModal;
