import React from 'react'
import { Container } from 'reactstrap'
import Navbar from '../../../Components/Common/navbar'
import Calender from '../../Package/calender'

export default function AmazingPassScreen() {

    return (
        <React.Fragment>
            <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
                <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
                    <Navbar />
                    <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>

                        <div style={{
                            display: "flex", flexDirection: "row",
                            justifyContent: "center", alignItems: "center", marginTop: "20px",
                            marginBottom: "20px"
                        }}>
                            <div style={{ fontSize: 19, fontFamily: "Gmarket-M", color: "#ff5a5f" }}>어메이징패스로 구매하기</div>
                        </div>


                        <div>
                            <Calender use_amazingpass={true} />
                        </div>

                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}
