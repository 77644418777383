import jwtDecode from "jwt-decode";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  UncontrolledCarousel,
  InputGroup,
  InputGroupText,
  CardHeader,
  Form,
} from "reactstrap";
import classnames from "classnames";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

import { clientKey, variantKey } from "../../../common/payments/toss/api";
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk";

import Navbar from "../../../Components/Common/navbar";
import { API, BACKEND_HOST } from "../../../api";
import qs from "qs";
import BgFrame from "../../../Components/Common/BgFrame";
import { getAccessToken } from "../../../api/auth";
import SelectCardBox from "../../../Components/Common/SelectCardBox";
import { nicepayClientId } from "../../../common/payments/nicepay/keys";
import { requestPaymentNicePay } from "../../../common/payments/nicepay/api";
import { getHectoPayParams, requestPaymentHecto } from "../../../common/payments/hecto/api";
import { hectoClientKey } from "../../../common/payments/hecto/keys";
import { useUserStore } from "../../../store/store";

//https://#{subdomain}.amazingticket.site/add-options-order?id=#{reserv_id}&deposit=true

const AddOptionsOrder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const { id, deposit } = query;

  const [orderData, setOrderData] = useState({
    reservationName: "",
    spotName: "",
    reservDate: "",
    reservId: "",
    options: [],
  });

  const [user, setUser] = useState({
    phoneNum: null,
  });
  const [name, setName] = useState("");
  const [cancelRuleAgree, setCancelRuleAgree] = useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = useState(false);
  const [tabList, setTabList] = useState([false, true]);

  const [sitePaymentKeys] = useUserStore((state) => [state.sitePaymentKeys]);
  const PGModules = useMemo(() => {
    const _PGModules = [];
    const _site = sitePaymentKeys.find((site) => site.client_id === SUBDOMAIN);
    if (!_site) return _PGModules;
    if (_site.use_hecto) {
      _PGModules.push({ id: 3, code: "hecto", label: "헥토페이" });
    } else if (_site.use_nicepay) {
      _PGModules.push({ id: 2, code: "nicepay", label: "나이스페이" });
    } else if (_site.use_toss) {
      _PGModules.push({ id: 1, code: "toss", label: "토스" });
    }
    return _PGModules;
  }, [sitePaymentKeys]);
  const [selectedPG, setSelectedPG] = useState(null);
  useEffect(() => {
    if (PGModules.length > 0) {
      setSelectedPG(PGModules[0]);
      console.log("PGModules", PGModules);
    }
  }, [PGModules]);

  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);

  const handleTabClick = (index) => {
    setTabList((prevTabList) => {
      const newTabList = [...prevTabList];
      newTabList[index] = !newTabList[index]; // toggle 로직
      return newTabList;
    });
  };

  const totalOrderPrice = useMemo(() => {
    return orderData.options
      .map((item) => {
        return parseInt(item.price * item.quantity);
      })
      .reduce((sum, currValue) => {
        return sum + currValue;
      }, 0);
  }, [orderData.options])

  const totalTaxfreePrice = useMemo(() => {
    return orderData.options
      .map((item) => {
        if (item.taxfree) {
          return parseInt(item.price * item.quantity);
        }
        return 0;
      })
      .reduce((sum, currValue) => {
        return sum + currValue;
      }, 0);
  }, [orderData.options])

  useEffect(() => {
    (async () => {
      if (!user.phoneNum) return;
      if (paymentMethodsWidgetRef.current) return;

      const customerKey = `${user.phoneNum}_${user.id}@amazingpay`
      console.log("customerKey", customerKey)
      console.log("totalOrderPrice", totalOrderPrice)
      const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
      if (paymentWidgetRef.current == null) {
        paymentWidgetRef.current = paymentWidget;
      }
      const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
        "#payment-method", { value: totalOrderPrice }, { variantKey: variantKey }
      );
      agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement('#agreement', { variantKey: variantKey });
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, [user, totalOrderPrice]);

  useEffect(() => {
    if (paymentMethodsWidgetRef.current != null) {
      paymentMethodsWidgetRef.current.updateAmount(totalOrderPrice);
      console.log("updateAmount totalOrderPrice", totalOrderPrice)
    }
  }, [totalOrderPrice]);

  useEffect(() => {
    if (location.state && location.state.reservId) {
      console.log("location.state", location.state);
      setOrderData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id && deposit) {
          console.log("id", id);
          console.log("deposit", deposit);

          const accessToken = getAccessToken();
          const reservationResponse = await API.get(`/package/get-reservation/${id}/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const reservationData = reservationResponse.data;
          console.log(`package/reservations/${id}/`, reservationData);

          if (!reservationData.package_id) {
            alert("예약 정보 로드 실패..");
            return;
          }

          const package_id = reservationData.package_id;
          const reservationName = reservationData.name;
          const spotName = reservationData.spot;
          const reservDate = reservationData.reservationDate;
          const reservId = reservationData.id;

          const itemsResponse = await API.get(`/package/items/${package_id}/`);
          const itemsData = itemsResponse.data;
          console.log(`package/items/${package_id}/`, itemsData);

          const depositOption = itemsData.options.find((option) => option.type === "deposit");
          setOrderData({
            reservationName: reservationName,
            spotName: spotName,
            reservDate: reservDate,
            reservId: reservId,
            options: depositOption
              ? [
                {
                  ...depositOption,
                  reservId: reservId,
                  optionId: depositOption.id,
                },
              ]
              : [],
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    // 함수를 호출하세요
    fetchData();
  }, [id, deposit]);

  const toggleAllAgree = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };
  const togglePurchaseAgreeOptions = (agree) => {
    // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const processPayment = async () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      alert("로그인이 필요한 서비스입니다.");
      navigate(`/login`);
      return;
    }

    if (totalOrderPrice === 0) {
      alert("결제가능한 최소금액은 1,000원입니다.");
      return;
    }

    try {
      const _body = {
        options: orderData.options.flatMap((item) => {
          return Array(item.quantity)
            .fill()
            .map(() => {
              return { reserv_id: item.reservId, option_id: item.optionId };
            });
        }),
      };
      console.log("orderData", _body);

      const response = await API.post("/package/pre-order-option/", _body, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const data = response.data;
      if ("error" in data) {
        throw new Error(data.error);
      }

      if (selectedPG.code === "toss") {
        const paymentWidget = paymentWidgetRef.current;

        paymentWidget?.requestPayment({
          orderId: data.orderId,
          orderName: `${orderData.reservationName}_추가옵션`,
          customerName: name,
          // customerEmail: "",
          customerMobilePhone: user.phoneNum,
          taxFreeAmount: totalTaxfreePrice,
          successUrl: `${BACKEND_HOST}/package/order-option/`,
          failUrl: `https://${SUBDOMAIN}.amazingticket.site/my-page`,
        });
      } else if (selectedPG.code === "nicepay") {
        requestPaymentNicePay({
          orderId: data.orderId,
          amount: totalOrderPrice,
          goodsName: `${orderData.reservationName}_추가옵션`,
          method: 'card',
          returnUrl: '/package/order-option-nice/',
          errorCb: (result) => {
            console.log("errorCb", result);
            alert("추가옵션을 결제하는데 실패했습니다.");
            navigate(`/my-page`);
          }
        })
      } else if (selectedPG.code === "hecto") {
        const encryptedParams = await getHectoPayParams({
          orderId: data.orderId,
          amount: totalOrderPrice,
          phone: user.phoneNum,
          user_id: user.id,
          email: user.email,
          username: user.username,
        });

        requestPaymentHecto({
          ...encryptedParams,
          mchtName: "아메이징캠프",
          mchtEName: "AmazingCamp",
          pmtPrdtNm: `${orderData.reservationName}_추가옵션`,
          notiUrl: `${BACKEND_HOST}/package/order-option-hecto/`,
          nextUrl: `${BACKEND_HOST}/package/order-done-hecto/`,
          cancUrl: `${BACKEND_HOST}/package/order-done-hecto/`,
        });
      } else {
        alert("결제모듈이 설정되지 않았습니다.");
      }

    } catch (error) {
      console.error(error);
      alert("추가옵션을 결제하는데 실패했습니다.");
      navigate("/my-page");
    }
  };

  const logOut = () => {
    alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.");
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessToken = getAccessToken();

        if (accessToken) {
          let decoded = {};
          try {
            decoded = jwtDecode(accessToken);
          } catch (e) {
            console.log(e);
            logOut();
          }

          const userId = decoded["user_id"];
          const response = await API.get(`/accounts/users/${userId}/`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = response.data;

          if (!data["전화번호"]) {
            logOut();
          } else {
            setUser({ id: userId, phoneNum: data["전화번호"], name: data["이름"] });
            setName(data["이름"]);
          }
        } else {
          setUser({
            phoneNum: null,
          });
        }
      } catch (error) {
        logOut();
      }
    };

    fetchUserData();
    // console.log("orderData.options", orderData.options)
  }, [location]);

  if (orderData.options.length === 0) {
    return (
      <React.Fragment>
        <div className="layout-wrapper landing" style={{ minHeight: "100vh" }}>
          <Navbar />
          <Container fluid>
            <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className="text-center">로딩중입니다...</div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
          <Navbar />

          <BgFrame>
            <Container fluid>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody className="checkout-tab">
                      <Form action="#">

                        <div>


                          <div>
                            <div
                              style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                              onClick={() => handleTabClick(0)}
                            >
                              <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                                주문자
                              </h5>
                              {tabList[0] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                            </div>
                            <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[0] ? "block" : "none" }}>
                              <Row className="gy-3">
                                <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>{name === "" ? "로그인 후 구매가능합니다." : name}</p>
                                <p style={{ margin: 0, fontFamily: "Gmarket-M" }}>{user.phoneNum ? user.phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") : ""}</p>
                              </Row>
                            </div>
                          </div>

                          <div style={{ width: "100%", height: "10px" }}></div>

                          <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[1] ? "block" : "none" }}>
                            <Row className="gy-3">
                              <Col lg={12} sm={12}>
                                <div className="">
                                  <div className="" htmlFor="orderInfo01">
                                    <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                      {orderData.reservationName} 추가 옵션
                                    </span>



                                    <span className="fw-normal text-wrap mt-1 mb-1 d-block">{location.state.reservDate ? `예약일 ${location.state.reservDate}` : null}</span>

                                    <span className="text-muted fw-normal text-wrap mb-4 d-block">
                                      {orderData.options.map((option, oidx, oobj) => {
                                        return (
                                          <span className="text-muted fw-normal d-block" key={oidx}>
                                            <div key={oidx} className="fw-normal text-wrap mb-1 mx-1" style={{ borderBottom: "1px solid #ededed", padding: "10px 0" }}>
                                              <span>
                                                <span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{orderData.spotName}</span>번 {option.price.toLocaleString()}원
                                              </span>
                                              <div className="d-block" style={{ textAlign: "right" }}>
                                                <p className="text-muted mb-0 fs-14 mb-1">
                                                  {/* {oidx === 0 ? "-" : ""} */}
                                                  {option.name} X {option.quantity}개
                                                  <span style={{ marginLeft: "5px" }}>
                                                    {(option.price * option.quantity).toLocaleString()}원{/* {oidx !== oobj.length - 1 ? ", " : ""} */}
                                                  </span>
                                                </p>
                                              </div>
                                            </div>
                                            {/* {option.name} - {option.price} */}
                                            {/* {oidx !== oobj.length - 1 ? ", " : ""} */}
                                          </span>
                                        );
                                      })}
                                    </span>

                                    <p style={{ textAlign: "right", marginTop: "10px", paddingRight: "10px" }}>
                                      총 금액 <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{` ${totalOrderPrice.toLocaleString()}원`}</span>
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>

                        <div style={{ width: "100%", height: "10px" }}></div>

                        <div>
                          <div
                            style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                          >
                            <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                              취소 및 환불 규정
                            </h5>
                          </div>
                          <div style={{ border: "1px solid #e9ebec", padding: "20px 20px" }}>
                            <Row className="gy-3">
                              <Col sm={6}>
                                {deposit ? (
                                  <span className="text-muted fw-normal text-wrap mb-1 d-block">청소보증금은 체크아웃 요청이 관리자에 의해 승인 되면 자동 환불</span>
                                ) : (
                                  <span className="text-muted fw-normal text-wrap mb-1 d-block">
                                    사용기간 내의 미사용 100% 환불
                                    <br />
                                    (유효기간 이후 환불불가)
                                  </span>
                                )}
                              </Col>
                            </Row>

                            <Col sm={12} className="py-3">
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="cancelRuleAgree"
                                  checked={cancelRuleAgree}
                                  onChange={(e) => {
                                    setCancelRuleAgree(e.target.checked);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="cancelRuleAgree">
                                  환불 규정에 동의하기
                                </label>
                              </div>
                            </Col>

                            <Col sm={12}>
                              <div className="form-check">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="purchaseOneAgree"
                                  checked={purchaseOneAgree}
                                  onChange={(e) => {
                                    togglePurchaseAgreeOptions(e.target.checked);
                                  }}
                                />
                                <label className="form-check-label" htmlFor="purchaseOneAgree">
                                  구매조건 확인 및 결제진행에 동의
                                </label>
                              </div>
                            </Col>
                          </div>
                        </div>

                        {PGModules.length > 1 ? <div className="max-w-540 w-100 mt-2">
                          <SelectCardBox
                            items={PGModules}
                            selectedItems={[selectedPG]}
                            onChange={(item) => {
                              setSelectedPG(item);
                            }}
                            isMulti={false}
                          />
                        </div> : null}

                        <div className={"max-w-540 w-100" + (selectedPG && selectedPG.code === "toss" ? " d-block" : " d-none")}>
                          <div id="payment-method" className="w-100" />
                          <div id="agreement" className="w-100" />
                        </div>

                        <div className="d-f lex align-items-start gap-3 mt-4 jus pb-3">
                          <button
                            type="button"
                            className="btn ms-auto nexttab"
                            style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                            onClick={() => {
                              if (!cancelRuleAgree || !purchaseAllAgree) {
                                alert("동의항목을 확인해주세요.");
                                return;
                              }
                              processPayment();
                            }}
                          >
                            결제하기
                            <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </BgFrame>
        </div>
      </div>
    </React.Fragment >
  );
};

export default AddOptionsOrder;
